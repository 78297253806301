.main_content .title_top_fix .flex_dash_title .dash_top_icon {
    margin-right: 15px;
    width: 31px;
    height: 31px;
    object-fit: contain;
}

.main_content .title_top_fix .flex_dash_title h4 {
    font-size: 20px;
    line-height: 33px;
    font-family: "Poppins-SemiBold";
}

/* .title_top_fix {
  padding: 4px 30px 10px 0px;
  position: fixed;
  left: 252px;
  right: 0;
  background: #f8f8f8;
  margin-top: 14px;
  height: 100vh;
} */

.over_text_scroll {
    height: calc(100vh - 230px);
    overflow: auto;
    padding-right: 10px;
    margin-right: -20px;
    /* padding-left: 20px; */
}

.card_list_ul .card_list_dash .card_list_dash_body {
    padding: 12px 20px 20px 20px;
}

.card_list_ul .card_list_li {
    width: calc(100% / 4 - 13px);
    margin: 0px 6.5px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 15px #0000000d;
    border: 1px solid #ececee;
    border-radius: 15px;
    padding: 0px;
}

.list_card_details .card_list_ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -6.5px;
    margin-right: -6.5px;
    width: 100%;
    padding: 0px;
}

.web_main_content .list_card_details {
    /* margin-bottom: 30px; */
    margin-bottom: 23px;
}

.card_list_ul .card_list_dash {
    width: 100%;
    background-color: transparent;
    box-shadow: none;
}

.number_title_card .number_list {
    font-size: 30px;
    line-height: 46px;
    font-family: "Poppins-Medium";
}

.number_title_card .number_menu_name {
    font-size: 16px;
    line-height: 25px;
    color: #858585;
    padding-bottom: 0px;
}

.card_list_ul .card_list_li .card_number_flex,
.progress_flex,
.flex_justify,
.flex_modal_space,
.flex_space_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 26px 10px 48px;
}

.progress_flex .left_progress,
.progress_flex .right_progress {
    font-size: 10px;
    line-height: 16px;
    margin-top: 0px;
    margin-bottom: 2px;
    color: #858585;
}

.progress-bar-main {
    margin-top: 6px;
}

.card_list_ul .card_list_li:hover {
    background-color: #000000;
}

.card_list_ul .card_list_li:hover .number_title_card .number_list {
    color: #fff;
}

.card_list_ul .card_list_li:hover .card_img_main {
    background: transparent linear-gradient(90deg,
            #f01e5c 0%,
            #f4314a 25%,
            #f94b25 50%,
            #fa5813 74%,
            #fd6602 100%) 0% 0% no-repeat padding-box;
    border: 0px;
}

.card_list_ul .card_list_li:hover .card_img_main .card_icon_black_none {
    display: block;
}

.card_list_ul .card_list_li:hover .card_img_main .card_icon_black {
    display: none;
}

.card_list_ul .card_list_li:hover .progress-bar-main .progress-bar .MuiLinearProgress-bar {
    background-color: #3e3e3e;
}

.card_list_ul .card_list_li:hover .progress-bar-main .MuiLinearProgress-colorPrimary {
    background-color: #1a1a1a;
}

.progress-bar-main .MuiLinearProgress-colorPrimary {
    background-color: #ececee;
}

.card_list_ul .card_list_li .card_img_main {
    background: #ececee 0% 0% no-repeat padding-box;
    border: 1px solid #e3e3e3;
    border-radius: 8px;
    height: 50px;
    width: 50px;
}

.card_icon_black_none {
    display: none;
}

.report-main-box {
    /* background: #ffffff;
  box-shadow: 0px 10px 15px #0000000d;
  border: 1px solid #ececee; */
    border-radius: 15px;
    width: 100%;
}

.report-main-box p {
    /* margin-left: 47px; */
    font-family: 'Nunito-SemiBold';
    padding: 5px 0px;

}

.page-table-main .table-container {
    box-shadow: none;
    background-color: transparent;
    border-radius: 0px;
}

.page-table-main .table {
    margin: 0px 0px;
}

.table-container .table .table-head {
    background: #dbf8fd;
}

.page-table-main .table-th {
    font-size: 13px;
    line-height: 21px;
    color: #000000;
    font-family: "Poppins-SemiBold";
    padding: 10px 10px 10px 12px;
    border-bottom: 1px solid #cfcfcf;
}

.page-table-main .table tbody td {
    border-bottom: 1px solid lightgray;
}

.page-table-main .table .table-th:nth-child(1),
.page-table-main .table .table-td:nth-child(1) {
    width: 10%;
}

.page-table-main .table .table-th:nth-child(2),
.page-table-main .table .table-td:nth-child(2) {
    width: 25%;
}

.page-table-main .table .table-th:nth-child(3),
.page-table-main .table .table-td:nth-child(3) {
    width: 15%;
}

.page-table-main .table tr:first-child .table-td {
    /* padding-top: 14px; */
}

.page-table-main .table-td {
    font-size: 12px;
    line-height: 21px;
    color: #858585;
    font-family: "Poppins-Regular";
    padding: 5px 15px 5px 19px;
    border-bottom: 0px;
    white-space: nowrap;
}

.page-table-main .table .table-td:first-child {
    color: #858585;
}

.userdata-btn-flex .table-btns {
    min-width: 22px;
    height: 22px;
    width: 22px;
    margin-right: 10px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.userdata-btn-flex .table-btns:last-child {
    margin-right: 0px;
}

.userdata-btn-flex .table-edit-btn,
.userdata-btn-flex .table-edit-btn:hover {
    background-color: #e6b903;
}

.userdata-btn-flex .table-eye-btn,
.userdata-btn-flex .table-eye-btn:hover {
    background-color: #61cfff;
}

.userdata-btn-flex .table-trash-btn,
.userdata-btn-flex .table-trash-btn:hover {
    background-color: #f91030;
}

.table-icons {
    height: 10px;
    width: 10px;
    object-fit: contain;
}


.mr-16px {
    margin-right: 16px;
}


.tale-pagination {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: 20px;
}

.bottom-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.tale-pagination ul .css-1y7coo4-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background: red;
    color: white;
    border: 1px solid rgb(255 255 255 / 50%);
}


.role_permission_text_box {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 8px 48px 8px 48px;
    background-color: #f6f6f6;
    border-top: 1px solid #dde2eb;
    border-bottom: 1px solid #dde2eb;
}

.role_permission_text_box p {
    font-size: 16px;
    font-family: "Nunito-SemiBold";
}


.role_permission_text_box .text_field input {
    padding: 8px 16px;
    font-family: "Nunito-Medium";
    font-size: 13px;
    width: 200px;
    color: gray;
    box-shadow: none;
}

.permission-heading p {
    text-align: start;
    font-size: 14px;
    font-family: "Nunito-Medium";
}

.permission-table .table-td {
    padding: 5px 15px 5px 5px;
}

.page-table-main .table tbody td:nth-child(2),
.page-table-main .table tbody td:nth-child(1) {
    padding-left: 15px;
}

.web_main_content .permission-table-box {
    height: calc(100vh - 300px);
}

.heading-right-side-table {
    display: flex;
    gap: 10px;
    align-items: center;
}

.role_permission_text_box .error,
.error-permission .error {
    color: red;
    font-size: 12px;
    font-family: "Poppins-Regular";
}

.table-body .no-data-cell {
    font-size: 13px;
    font-family: "Poppins-Regular";
    color: gray;
}

.error-permission .error {
    width: 100%;
}

.main-user-table .report-table-main {
    box-shadow: none;
}

.left-header-main p {
    font-size: 15px;
    font-family: "Nunito-ExtraBold";
}

/* .custom-my-table-user .table-container .custom-table tbody tr td {
  padding: 5px 10px !important;
} */


.form-wrape {
    border-radius: 14px;
    padding: 15px;
    background-color: #fff;
}

.card-btn-flex {
    width: 180px;
    margin-left: auto;
}

.crtl-wrape .inner-pad23 p.Mui-error {
    font-size: 13px;
    color: red;
    font-family: var(--head-text);
}